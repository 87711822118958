import React, {useState, useEffect} from "react"
import { Container, Row, Col } from "react-bootstrap"
import HTMLReactParser from "html-react-parser"
import ShowMoreText from 'react-show-more-text';

// Styles
import "./AreaGuide.scss"
import CustomButton from "../../../components/CustomButton"
import {CustomLinks} from "../../../components/common/menuUtils"
import {ImageModule} from '../../../modules/Image_Module'

// Components
import SectionWrapper from "../../../components/SectionWrapper"
import SectionHeader from "../../../components/SectionHeader"

import {AreaGuidebyName} from "../../../queries/common_use_query"

const AreaGuide = (props) => {
    const [result, setResult] = useState([]);

    const {loading, error, data} = AreaGuidebyName(props.area);

    useEffect(()=>{
        data && data.areaGuides.length > 0 && setResult(data.areaGuides[0]);
    },[data])
 
    return (
        <>
        {data && data.areaGuides.length > 0 &&
            <SectionWrapper className="areaguide-wrapper">
                <Container>
                    <Row>
                        <Col
                            lg={ 7 }
                        >
                            <div className="video-container areaguide_img">
                                <ImageModule ImageSrc={result.Banner_Image} altText={result.Name}  />  
                            </div>
                        </Col>
                        <Col>
                            <div className="content-wrapper">
                                <SectionHeader
                                    sectionHeaderData={ {
                                        Title: result.Name,
                                    } }
                                />
                                <div className="content">
                                    {/* {result.Content && HTMLReactParser(result.Content) */}
                                    {result.Content &&
                                    <ShowMoreText
                                        lines={10}
                                        more=''
                                        less=''
                                        className='content-css'
                                        anchorClass='btn-more review-more'
                                        expanded={false}
                                    > 

                                        {HTMLReactParser(result.Content)}
                                    </ShowMoreText> 
                                    }
                                </div>
                                

                                <div>
                                    <CustomButton
                                        value="VIEW MORE"
                                        variant="outline"
                                        link={`/${CustomLinks.explore_london}/${result.URL}/`}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </SectionWrapper>
        }
        </>
    )
}

export default AreaGuide
