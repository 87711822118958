import * as React from 'react';
import { Container } from "react-bootstrap";

// Components
import SectionWrapper from "../../../components/SectionWrapper";
import SectionHeader from "../../../components/SectionHeader";
import PropertyCards from "../../../components/PropertyCards";

// Styles
import './SimilarProperties.scss';

const SimilarProperties = ( { similarPropertiesData } ) => {

    const sectionHeaderData = {
        Title: similarPropertiesData.Title,
        SubTitle: similarPropertiesData.SubTitle,
        Link: {
            FullText: 'View All Properties',
            ShortText: 'View All',
            Href: '#'
        }
    }

    return (
        <SectionWrapper className="similar-properties-wrapper" >
            <Container>
                <div className='property-cards-header-section'>
                    <SectionHeader
                        sectionHeaderData={ sectionHeaderData }
                    />
                </div>
                <div className="sub-section-wrapper property-cards-body-section">
                    <PropertyCards
                        propertyCardsData={ similarPropertiesData.Properties }
                        layout={ {
                            lg: 4,
                            sm: 6,
                            xs: 12,
                        } }
                    />
                </div>
            </Container>
        </SectionWrapper>
    );
};

export default SimilarProperties;
